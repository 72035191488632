<template>
    <div id="footer">
        <div class="ul-list">
        <div class="list">
            <p>行业方案</p>
            <ul>                
                <li>
                    <router-link :to="{ path: '/programme', hash: '#parkPoint' }">智慧园区</router-link>
                </li>
                <li>
                    <router-link :to="{ path: '/programme', hash: '#IndustryPoint' }">智慧工贸</router-link>   
                </li>
                <li>
                    <router-link :to="{ path: '/programme', hash: '#trafficPoint' }">智慧交通</router-link>         
                </li>
                <li>
                    <router-link :to="{ path: '/programme', hash: '#constructionPoint' }">智慧工地</router-link>          
                </li>
                <li>
                    <router-link :to="{ path: '/programme', hash: '#waterPoint' }">智慧水务</router-link>  
                </li>
            </ul>
        </div>
        <div  class="list-center">
            <p>经典案例</p>  
            <ul>
                <li>             
                    <router-link :to="{ path: '/product', hash: '#monitorPoint' }">视频综合管理平台</router-link>
                </li>
                <li>双重预防机制信息平台</li>
                <li>应急指挥平台</li>
            </ul>
        </div>
        <div class="list-center">
            <p>关于我们</p>  
            <ul>
                <li>
                    <router-link :to="{ path: '/about', hash: '#aboutPoint' }">公司介绍</router-link>
                </li>
                <li>
                    <router-link :to="{ path: '/about', hash: '#honorPoint' }">荣誉资质</router-link>

                </li>
                <li>
                    <router-link :to="{ path: '/about', hash: '#allmap' }">联系我们</router-link>
                </li>
            </ul>
        </div>
        </div>
        <div class="right-box">
        <img src="../assets/img/footer-logo.png" alt="">
        <p class="text">武汉东湖新技术开发区金融港一路7号神州数码武汉科技园1栋16层10号</p>
        <p class="tel">400 100 4987</p>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        // go(){
        //     console.log('123');
        //     this.$router.push({
        //         path:"/programme",
        //     })
        // }
    }
}
</script>
<style  scoped>
#footer{
    height: 320px;
    background: #292929;
    padding: 66px 230px;
    color: #fff;
    display: flex;
 justify-content: space-between;
}
#footer .ul-list{
 width: 48%;
 height: 137px;
 min-width: 500px;
 display: flex;
 justify-content: space-between;
}
#footer .ul-list .list{
    width: 162px;
}
#footer .ul-list .list p{
    font-size: 16px;
    margin-bottom: 20px;
    font-family: 'PingFang SC';
    font-weight: bold;
}
#footer .ul-list .list ul{
    display: flex;
    flex-wrap: wrap;
}
#footer .ul-list .list ul li{
 color: rgba(255,255,255,0.7) !important;
    margin-bottom: 20px;
    margin-right: 25px;
}
#footer .ul-list .list ul li a{
    color: rgba(255,255,255,0.7) !important;

}
#footer .ul-list .list-center{
    width: 162px;

}
#footer .ul-list .list-center p{
    font-size: 16px;
    margin-bottom: 20px;
    font-family: 'PingFang SC';
    font-weight: bold;
}
#footer .ul-list .list-center ul{
    display: flex;
    height: 90px;
    flex-direction: column;
    justify-content: space-between;  
 color: rgba(255,255,255,0.7);
}
#footer .ul-list .list-center ul li a{
 color: rgba(255,255,255,0.7);

}
#footer .right-box{
    width: 364px;
}
#footer .right-box img{
    margin-bottom: 15px;
}
#footer .right-box .text{
 color: rgba(255,255,255,0.7);
font-size: 14px;
margin-bottom: 24px;
}
#footer .right-box .tel{
 color: rgba(255,255,255,0.7);
    font-size: 32px;
    font-family: 'PingFang SC';
    font-weight: 400;
}
</style>
<template>
<div>
  <div class="nav-top">
    <div class="logo">
      <img src="../assets/img/logo.png" alt="">
    </div>
    <div class="nav-btn">
      <a :class="router==='/'?'active':''"  href="/">首页</a>
      <a :class="router==='/shop'?'active':''" href="/shop" @mouseover="()=>{shopShow=true}" @mouseleave="()=>{shopShow=false}" >算法商城</a>
      <a :class="router==='/product'?'active':''" href="/product">产品中心</a>
      <a :class="router==='/maintenance'?'active':''" href="/maintenance">运维服务</a>
      <a :class="router==='/programme'?'active':''" href="/programme" @mouseover="()=>{programmeShow=true}" @mouseleave="()=>{programmeShow=false}" >行业方案</a>
      <a :class="router==='/about'?'active':''" href="/about">关于我们</a>
    </div>

    <div class="tel">
      <img src="../assets/img/tel.png" alt="">
      <p>400 100 4097</p>
    </div>
  </div>
  <div v-show="shopShow"  class="hover-box">
     <div class="hover-list" @mouseover="()=>{shopShow=true}" @mouseleave="()=>{shopShow=false}" >
        <ul>
          <li v-for="item in shopList" :key="item.text">
            <div class="title">
              <img :src="item.url" alt="">
              <p class="text">{{item.text}}</p>
            </div>
            <div class="list" v-for="ele in item.child" :key="ele.text">
              <p>{{ele.text}}</p>
            </div>
          </li>
        </ul>
     </div>
  </div>
    <div v-show="programmeShow"  class="hover-box">
     <div class="hover-list" @mouseover="()=>{programmeShow=true}" @mouseleave="()=>{programmeShow=false}">
        <ul class="programme-list">
          <li v-for="item in programmeList" :key="item.text">
            <div class="title" @click="changePro(item.text)">
              <img :src="item.url" alt="">
              <p class="text">{{item.text}}</p>
            </div>
          </li>
        </ul>
     </div>
  </div>
  </div>
</template>

<script>  
import { mapState } from 'vuex'
export default { 
  data(){
    return{
      shopShow:false,
      programmeShow:false,
      shopList:[
        {
         text:"建筑地产",
         url:require('../assets/img/property.png'),
          child:[
            {text:"反光衣识别"},
            {text:"安全帽识别"},
            {text:"火焰识别"},
            {text:"重型机械识别"},
            {text:"烟雾识别"},
          ]
        },
        {
         text:"智慧城管",
         url:require('../assets/img/management.png'),
          child:[
            {text:"道路积水识别"},
            {text:"井盖缺失识别"},
            {text:"沿街晾晒识别"},
            {text:"占道经营识别"},
            {text:"热门街道垃圾识别"},
          ]
        },
        {
         text:"智慧工业",
         url:require('../assets/img/industry.png'),
          child:[
            {text:"护目镜识别"},
            {text:"下料口堵塞识别"},
            {text:"皮带传输监测预"},
            {text:"仪表盘读数识别"},
            {text:"机柜指示灯识别"},
          ]
        },
        {
         text:"智慧能源",
         url:require('../assets/img/energy.png'),
          child:[
            {text:"风电叶片缺陷识别"},
            {text:"静电服识别"},
            {text:"电线杆鸟巢识别"},
            {text:"明火与烟雾识别"},
            {text:"无人机光伏巡检算法"},
          ]
        },
        {
         text:"智慧交通",
         url:require('../assets/img/traffic.png'),
          child:[
            {text:"车辆违停识别"},
            {text:"机动车占道识别"},
            {text:"行人闯红灯识别"},
            {text:"电动车头盔识别"},
            {text:"车牌识别"},
          ]
        },
        {
         text:"智慧园区",
         url:require('../assets/img/park.png'),
          child:[
            {text:"反光衣识别"},
            {text:"安全帽识别"},
            {text:"火焰识别"},
            {text:"重型机械识别"},
            {text:"烟雾识别"},
          ]
        },
        {
         text:"智慧水务",
         url:require('../assets/img/water.png'),
          child:[
            {text:"岸边垃圾识别"},
            {text:"水体颜色识别"},
            {text:"船舶闯入识别"},
            {text:"船舶类型识别"},
            {text:"河道漂浮物识别"},
          ]
        },
        {
         text:"智慧零售",
         url:require('../assets/img/retail.png'),
          child:[
            {text:"客流特征识别(年龄、性别)"},
            {text:"客流密度统计"},
            {text:"贵重物品识别"},
            {text:"扶梯大件行李识别"},
            {text:"客流统计"},
          ]
        },
      ],
      programmeList:[
        {text:"智慧园区",url:require('../assets/img/park.png')},
        {text:"智慧工贸",url:require('../assets/img/industry.png')},
        {text:"智慧交通",url:require('../assets/img/traffic.png')},
        {text:"智慧工地",url:require('../assets/img/property.png')},
        {text:"智慧水务",url:require('../assets/img/water.png')},
      ],
    }
  },
  computed: 
  {
    router(){ return this.$store.state.router }
  },
  methods:{
    changePro(ele){
      this.$store.state.programme = ele
      this.programmeShow =false
      if (this.router==='/programme') {
               return        
      }else{
        this.$router.push({
            name:"programme"
        })
      }
    }
  }
 }
</script>  

<style  scoped>
.nav-top{
  background-color: #fff;
  box-sizing: border-box;
  padding: 0 10%;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  line-height: 70px;
}
.nav-top .logo{
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-top .nav-btn a{
      color: #333333 ;
      display: inline-block;
      margin-right: 4vw;
}
.nav-top .nav-btn a.active{
  color: #4088FF;
  font-weight: bold;

}
.nav-top .nav-btn a:hover{
  color: #4088FF;
}
.nav-top .tel{
  display: flex;
  line-height: 70px;
  justify-content: center;
  align-items: center;
}
.nav-top .tel img{
  /* width: 12px;
  height: 12px; */
  margin-right: 5px;
}
.nav-top .tel p{
  font-size: 16px;
  font-family: 'PingFang SC';
  color: #4088FF;
}
.hover-box{
  top: 70px;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color:rgba(0, 0, 0, .5);
  z-index: 99;
}
.hover-box .hover-list{
  /* height: 500px; */
  /* min-width: 1400px; */
  background-color: #fff;
  padding: 50px 12%;
}
.hover-box .hover-list ul{
  min-width: 1000px;
  display: flex;
  flex-wrap: wrap;
}
.hover-box .hover-list ul li{
  height: 150px;
  width: 260px;
  margin-bottom: 65px;
}
.hover-box .hover-list ul li:hover{
  cursor: pointer;
}
.hover-box .hover-list ul li .title{
   display: flex;
   font-size: 14px;
   color: #333333;
   font-weight: bold;
   margin-bottom: 15px;
}
.hover-box .hover-list ul li .title img{
  margin-right: 14px;
}
.hover-box .hover-list ul li .list{
  color: #666666;
}
.hover-box .hover-list ul li .list p{
  margin-bottom: 8px;
}
.hover-box .hover-list .programme-list li{
  width: 200px;
}
</style>
<template>
  <div id="app">
      <!-- <Nav id="nav" />
       <router-view/>
      <Footer /> -->
      <router-view/>
  </div>
  <!-- <div id="app">
    <div class="Mobile" v-if="isMobile">
      <img  src="../src/assets/img/Mobile.png" alt="">
    </div>
    <div v-else>
      <Nav id="nav" />
      <router-view/>
      <Footer />
    </div>
  </div> -->
</template>

<script>
import Nav from '@/components/nav.vue'
import Footer from '@/components/footer.vue'

export default {
  components: {
    Nav,
    Footer
  },
  data() {
    return {
      isMobile:false
    }
  },
  created(){ 
  this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
</script>
<style  scoped>
 #app .Mobile{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 #app{
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
 }
</style>

import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import BaiduMap from 'vue-baidu-map'
import './assets/font/font.css'


import './assets/css/reset.css'
import './assets/css/public.css'
import './assets/css/common.css'


Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})

Vue.config.productionTip = false
Vue.use(ElementUI)
.use(BaiduMap, {
  ak: '9nKZ560M6aEfNGk9OSj0GKm4hiCQFpco'  //这个地方是官方提供的ak密钥
})

new Vue({
  el: '#app',
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

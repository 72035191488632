import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: '/',
    name: 'layout',
    component: ()=>import('../views/layout/index.vue'),
    children:[ {
      path: '/home',
      name: 'home',
      component: ()=>import('../views/home/home.vue')
    },
    {
      path: '/shop',
      name: 'shop',
      component:  ()=>import('../views/shop/shop.vue')
    },
    {
      path: '/product',
      name: 'product',
      component:  ()=>import('../views/product/product.vue')
    },  
    {
      path: '/programme',
      name: 'programme',
      component:  ()=>import('../views/programme/programme.vue')
    },
    {
      path: '/shop/info',
      name: 'info',
      component:  ()=>import('../views/shop/shopInfo.vue')
    },
    {
      path: '/about',
      name: 'about',
      component:  ()=>import('../views/about/about.vue')
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component:  ()=>import('../views/maintenance/maintenance.vue')
    }]
  },{
    path: '/player',
    name: 'player',
    component:  ()=>import('../views/wx-player/wx-player.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 将当前路由存到vuex中
  store.state.router =  to.path
  next()

})
export default router
